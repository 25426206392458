.top-area .user-img > img{
	width: 45px !important;
    height: 45px !important;
}

.feature-photo > figure img{
	height: 200px !important;
    object-fit: cover;
}

.form-group input[readonly]~.control-label{
	color: #2a2a2a;
	top: -1rem;
    left: 0;
	font-size: .8rem;
}

button:disabled, a.off{
	background:#f05a24a1 none repeat scroll 0 0 !important;
	cursor: not-allowed;
}

.input-group{
	margin-bottom: 10px;
}

.input-group .control-label{
	display: block;
    width: 100%;
    font-size: .8rem;
    color: #f05a24;
	margin: 0px;
}

.input-group input{
	display: block;
    background: 0 0;
    padding: 0.125rem 0.125rem 0.0625rem;
    font-size: 1rem;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    width: 100%;
	height: 1.9rem;
    transition: all .28s ease;
    box-shadow: none;
}

.input-group input:focus{
	outline: none;
	box-shadow:none;
	border-color:transparent;
}

.input-group .mtrl-select{
	position: relative;
    border-bottom: 0.0625rem solid #e1e8ed;
    display: block;
	width: 100%;
}

.input-group .mtrl-select::before{
	content: '';
    height: 0.1rem;
    width: 0;
    left: 50%;
    bottom: -0.1rem;
    position: absolute;
    background: #088dcd;
    transition: left .28s ease, width .28s ease;
    z-index: 2;
}

.input-group input:focus~.mtrl-select::before{
	width: 100%;
    left: 0;
}

.form-control:disabled, .form-control[readonly]{
	background-color: transparent
}

.datepicker-dropdown{
	margin-top: 26px !important;
}

.fa-7x{
    font-size:7em;
}

.bg-theme{
    background:#f05a24 !important;
}

.post-comt-box form button{
    bottom: 5px !important;
}

.form-group input, .form-group textarea{
    color: initial !important
}

.preloader{
    position: fixed;
    background-color: #f4f2f2;
    background-position: center center;
    background-repeat: no-repeat;
    top: 60px;
    left: 70px;
    right: 70px;
    bottom: 0;
    z-index: 9991;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.file-picker-theme{
    margin: 10px 0px;
    border: 1px solid #eeeeee;
    padding: 4px;
}

.idea-sub-sections{
    background: white;
    border: 1px solid #ede9e9;
    padding: 20px;
    margin-bottom: 20px;
}

.alert2{
    padding: 5px 10px !important;
    background-color: #d4eddac9 !important;
}

.alert2 a{
    color: #F05A23;
}

.alert p{
    margin:0px !important;
}

.ofr-slr-rsp{
    background: #F4F2F2;
    margin-top: 10px;
    padding: 10px;
}

.no-search + div .chosen-search{
    display: none;
}

.chosen-search input{
    width: 100%;
}

.no-search + div .chosen-results{
    margin: 4px 4px 4px 0 !important;
    padding: 4px 0 0 4px !important;
}

.create-feed-profile img{
    border-radius: 50%;
    width:64px;
    height:64px
}

.create-feed-profile::after{
    content:'';
    clear:both;
    display: block;
}

.ml-10{
    margin-left: 10px;
}

a.mtr-btn{
    border-radius: 4px;
    padding: 8px 20px;
    font-size: 16px;
}

.user-setting{
    left: -130px !important;
    width:200px !important;
}

.preloader.full{
    left:0 !important;
    right:0 !important;
    top:0 !important;
}

.date input{
    box-shadow: none !important;
}

.ex-tooltip{
    cursor:pointer;
    display: inline-block;
}

.pst-carousel .owl-nav button{
    background: transparent;
    border: 0;
}

.owl-carousel .owl-item img{
    max-width: 100%;
    max-height: 220px;
    object-fit: cover;
}

.peoples > li.active{
    background: #e1e8ed;
}

.mb0, .mb0 li{
    margin-bottom: 0px !important;
}

.img-max-250{
    max-height: 250px;
    min-height: 250px;
    object-fit: cover;
}

.idea-min-height{
    min-height: 230px;
    overflow: hidden;
}

.profile-img{
    width:60px;
    object-fit: cover;
}

.profile-img-small{
    width:35px;
    height:35px;
    object-fit: cover;
}

.profile-img-feed{
    /* width:50px; */
    width: 48px;
    height:48px;
    object-fit: cover;
}

.btn-success{
    background: #f05a24 !important;
    border-color: #f05a24 !important;
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus{
    box-shadow:none !important;
}

a.btn{
    color: #fff;
    font-size: 13px;
    line-height: initial;
    padding: 5px 10px;
    text-transform: capitalize;
    border-radius: 4px;
    background: #f05a24;
    display: inline-block;
    margin: 0px 5px;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active{
    background: #666666 !important;
    border-color: #666666 !important;
}

.mtr-btn:hover,
.mtr-btn:focus,
.mtr-btn:active{
    background: #666666 !important;
}

.main-btn:hover,
.main-btn:focus,
.main-btn:active{
    background: #666666 !important;
}

.mtr-btn::before{
    border-color: #666666 !important;
}

a.btn.more-action{
    background: lightslategray none repeat scroll 0 0;
}

.btn-bordered:hover,
.btn-bordered:focus,
.btn-bordered:active,
.btn-bordered{
    border:1px solid #f05a24;
    color:#f05a24;
}

.bootstrap-tagsinput{
    width: 100% !important;
}

.banermeta {
    margin-top: 10px !important;
}

.pwd-eye{
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
    cursor: pointer;
    color: #000;
}

.hummingbird-treeview,
.hummingbird-treeview *
{
    list-style: none;
    font-size: 20px;
    line-height: 24px;
}

.hummingbird-treeview label {
    font-weight: normal;
}


.hummingbird-treeview input[type=checkbox] {
    width : 16px;
    height :16px;
    padding: 0px;
    margin: 0px;
}

.hummingbird-treeview ul:not(.hummingbird-base) {
    display: none;
}

.hummingbird-treeview .fa {
    font-style: normal;
    cursor: pointer;
}

.react-checkbox-tree{
    display:block !important;
    padding: 0 20px 20px;
}

.react-checkbox-tree label:hover{
    background:transparent !important;
    color:#f05a24;
}

.react-checkbox-tree .rct-node{
    margin-bottom: 0px !important;
}

.react-checkbox-tree .rct-node-expanded .rct-text span[aria-checked="true"] {
    color:#f05a24;
}

.react-checkbox-tree .rct-node-expanded .rct-text span[aria-checked="true"] + span + span {
    color:#f05a24;
}

.react-checkbox-tree .rct-title{
    padding: 0px;
}

.react-checkbox-tree .rct-node-icon{
    display: none;
}

.react-checkbox-tree .rct-collapse-btn{
    padding:0px;
}

.react-checkbox-tree ol ol{
    padding-left: 18px;
}

.rct-collapse{
    -ms-flex-item-align: start;
    align-self: start;
    margin-top: 3px;
}

.rct-node-leaf label{
    margin-left: 15px;
}

.rct-node-leaf .rct-collapse{
    display: none;
}

.btn-theme{
    background: #f05a24 !important;
    color: #FFF !important;
}

.tooltip-inner{
    max-width:400px !important;
}

.notification-box > ul > li.unread{
    background: #fff2ed !important;
    border-color: #ffe9e0 !important;
}

.full-modal{
    max-width: 100%;
    height: 100%;
    margin: 0px;
}

.full-modal .modal-content{
    height: 100%;
    overflow: auto;
    border-radius:0px !important;
    margin: 0 auto;
    width: 50%;
}

.full-modal .modal-content .modal-body{
    padding: 2rem 5rem;
}

div[noMargin]{
    margin:0px !important;
}

.mr-50{
    margin-right: 50px;
}

.user-setting a img{
    width: 16px;
    height: 16px;
    margin-right:6px;
}

.custom-switch{
    display: inline-block;
    background: #9f9f9f;
    color: #FFF;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
}

.custom-switch span{
    padding: 5px 10px;
    display: inline-block;
}

.custom-switch .on{
    background: #F05A23;
}

.btn-send-msg{
    opacity: 1 !important;
    visibility: visible !important;
    height: 50px;
    border-radius: 0;
}

.chat-list > div{
    height: 300px;
    overflow: auto;
}

.chat-list{
    padding: 0px !important;
}

#scrollableDiv{
    width: 100% !important;
}

#scrollableDiv .infinite-scroll-component, #scrollableDivIdea .infinite-scroll-component {
    padding: 15px !important;
}

#scrollableDivIdea{
    width: 100%;
}

.author-thmb .badge{
    position: absolute;
    bottom: 0;
    left: -20%;
    border-radius: 100%;
}

.pricing .current-plan{
    background: #f05a24;
}

.pricing .current-plan.plan2-br,
.pricing .current-plan hr{
    border-color:#FFF !important;
}

.pricing .current-plan .card-body,
.pricing .current-plan li,
.pricing .current-plan .card-price,
.pricing .current-plan .text-muted
{
    color: #FFF !important;
}

.pricing .current-plan .text-muted{
    opacity: 0.6 !important;
}

.pricing .current-plan .btn{
    background: #FFF !important;
    color: #F05A23 !important;
}

.text-theme{
    color: #F05A23 !important;
    border-bottom: 3px solid;
    display: inline-block;
    margin: 0 auto;
}

.employer-info > ul li span{
    width: 150px !important;
}

.comet-avatar > img{
    min-width: 32px !important;
    width: 32px !important;
    height: 32px !important;
}

.modal-body p{
    font-size: 16px;
    margin: 0;
}

/*.tlr-content{
    display: block;
    width: 100%;
    margin: 10px 0px;
}

.tlr-content label span{
    padding:10px;
    display: inline-block;
    margin-left: 40px;
}

.tlr-content label{
    display: block;
    position: relative;
    margin: 0px;
    color: #FFF;
    cursor: pointer;
    text-transform: uppercase;
}

.tlr-content label::before{
    position: absolute;
    top:0px;
    left: 0px;
    margin-right: 10px;     
    content: counter(Serial); 
    counter-increment: Serial -1; 
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    height: 100%;
    width: 40px;
    padding: 10px;
    text-align: center;
    vertical-align: -50%;
}

.tlr-content label.selected::before{
    content: '\f00c';
}

.tlr-content label.one{
    background: #ec671c;
}

.tlr-content label.one:hover,
.tlr-content label.one.selected{
    background: #ec671cd9;
}

.tlr-content label.two{
    background: #ec671c;
}

.tlr-content label.two:hover,
.tlr-content label.two.selected{
    background: #ec671cd9;
}

.tlr-content label.three{
    background: #ec671c;
}

.tlr-content label.three:hover,
.tlr-content label.three.selected{
    background: #ec671cd9;
}*/

body{
    counter-reset: Serial 10;          
}

.intl-tel-input{
    display: block !important;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input, .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=text], .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type=tel]{
    padding-left: 50px !important;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code{
    padding-left: 5px;
    display:none;
}

.iti-flag{
    position: relative;
    top: 0%;
    transform: translateY(50%);
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 .selected-flag{
    width: 36px;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag{
    background-color: transparent;
}

.intl-tel-input .flag-container .arrow{
    font-size: 7px;
    margin-left: 27px;
    position: relative;
    top: -4px;
}

.nopos{
    position:relative !important;
    float:none !important;
}

.nopos.more-action{
    right:0px;
}

.modal-body .sign-nda p{
    font-size: 16px !important;
}

p.para{
    margin: 10px 0px;
}

.mh-head .mh-btns-right{
    width: 80px !important;
}

.phone-chat-users-content .row{
    padding: 15px;
}

.phone-chat-users.show{
    display: block;
}

.phone-chat-users{
    background: white;
    height: 90vh;
    display: none;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    overflow:hidden;
    right: 0;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
    border-radius: 4px;
    margin: 15% 10px 0px 10px;
}

.phone-chat-users li{
    margin: 0px;
    padding: 10px;
    border-bottom: 1px solid #F3F3F3;
}

.phone-chat-users li label{
    font-weight: bold;
    display: inline-block;
    padding: 6px 12px;
    margin: 0px;
}

@media screen and (max-width: 640px){

    .chat-box.show{
        left: 0;
        top: 0;
        border: 0;
        right: 0;
        width: 100%;
        height: 100vh;
    }

    .chat-box .chat-list .chat-lst{
        height: 80vh;
    }

    #scrollableDiv{
        height: 100% !important;
    }
}

.cht-cnt{
    position: fixed;
    top: 0;
    right: 80px;
    width: 100%;
    height: 100%;
    z-index: 99999;
    width: 0px;
}

.landing-page .info{
    padding-left: 68px !important;
}

.landing-page .vid-sub-heading{
    margin-top: 10px;
    font-size: 32px;
    margin-bottom: 30px;
}

.mh-head .mh-btns-right.w-40{
    width: 40px !important
}

.topbar{
    position: fixed !important;
    width: 100% !important;
}

.sidebar .widget .naves li{
    cursor: pointer;
}

.about-scions .team-member-image{
    width: 16px;
    height: 16px;
}

.modal-transp{
    max-width: 60% !important;
}

.modal-transp .modal-content{
    background: transparent !important;
    border:0px !important
}

.modal-transp .modal-content .modal-body{
    padding: 0px !important;
}

.tlr-content .table td,
.tlr-content .table th{
    border-color: #f6af98;
}

.tlr-content .from-1-3{
    /* background:rgb(150 150 150 / 33%); */
    background: #fbdfd5;
    color: #000;
}

.tlr-content .from-4-6{
    /* background: rgb(150 150 150 / 50%); */
    background: #f9cfc1;
    color: #000;
}

.tlr-content .from-7-9{
    /* background:rgb(150 150 150 / 80%); */
    background: #f7bfac;
    color: #000;
}

.tlr-content label{
    margin: 0px !important;
}


.about-scions .bluesh:before {
    background: #f05a24;
    z-index: 1;
}

.grecaptcha-badge{
    z-index: 99999;
}

@media (max-width:768px)
{

    .mobile-footer div{
        text-align: center !important;
    }

    .mobile-footer div:last-child{
        margin-top: 10px !important;
    }

    .modal-transp, .modal-lg{
        max-width: 100% !important;
    }

    .feature-photo{
        height: 115px;
    }

    .custom-modal-cnt{
        width: 90% !important;
    }

    .profile-completness h4{
        font-size: 1rem !important;
        margin-top: -5px;
    }
    
    .profile-completness p{
        line-height: 19px;
    }

    .mt-10-m{
        margin-top: 10px !important;
    }

    .btn-hiw{
        margin: 20px 10px !important;
    }

}

.btn-hiw{
    margin: 10px;
}

.card-highlight{
    border: 2px solid #f05a24;
    background: #FFF;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 20%);
}

.invite-popup .widget:after{
    content: '';
    display: block;
    clear: both;
}

.invite-popup .widget{
    float: none;
}

.invite-popup .modal-content{
    background: transparent !important;
    border:0px !important
}

.invite-popup .modal-content .modal-body{
    padding: 0px !important;
}

.invite-popup .close-btn{
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
    color: #FFF;
    opacity: 1;
    display: block !important;
    font-weight: normal;
}

.no-overflow{
    overflow: hidden;
}

.custom-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #32323263;
    z-index: 999999;
    display: none;
}

.custom-modal.show{
    display: block;
}

.custom-modal-cnt{
    background: #FFF;
    width: 30%;
    margin: 0;
    border-radius: 0.3rem;
    padding: 20px;
    top:50%;
    left:50%;
    position: absolute;
    overflow: hidden;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.custom-modal-header{
    background: #f05a24;
    color: #FFF;
    padding: 10px;
}

.custom-modal-header h5{
    margin: 0px;
    font-size: 16px;
    text-align: center;
}

.custom-modal-content{
    padding: 20px;
}

.custom-modal-content .profile-details *{
    color:#000;
}

.custom-modal-content .profile-details .image-sec{
    text-align: center;
}

.custom-modal-content .profile-details .image-sec .u-profile{
    border-radius: 10px;
    height: 120px;
    background: #f4f2f2;
}

.custom-modal-content .profile-details p{
    margin: 0px;
}

.custom-modal-content .profile-details .image-sec .ic{
    width: 24px;
}

.custom-modal-content .profile-details .image-sec a{
    margin: 10px;
}

.custom-modal-content .profile-details .details-sec{
    text-align: left;
}

.custom-modal-content .pd-boxes{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.custom-modal-content .pd-boxes .pdb{
    background: #EBF8FE;
    border: 1px solid #c3d0d8;
    border-radius: 15px;
    min-width: 30%;
    max-width: 30%;
    display: flex;
    align-items: center;
    padding: 10px;
    flex-grow: 1;
    margin: 10px;
}

.custom-modal-content .pd-boxes .pdb .rnd{
    width: 80px;
    height: 80px;
    background: #d0e4f3;
    display: inline-block;
    border-radius: 100px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-modal-content .pd-boxes .pdb h1{
    margin: 0;
    color: #000;
}

.custom-modal-content .pd-boxes .pdb strong{
    margin-left: 10px;
    color: #000;
    font-weight: normal;
}

.profile-completness{
    color: #FFF;
    width: 100%;
    padding: 15px;
    display: inline-block;
}

.word-limiter-show{
    position: absolute;
    right: 0;
    display: inline-block;
}

.err-link{
    color: #0190d2;
    text-decoration: underline;
}

.whats-new p{
    margin: 0px !important;
}

.ex-popover{
    display: inline-block;
}

.ex-popover-span{
    font-size: 12px !important;
    margin-left: 20px;
    cursor: pointer;
}

.dark-popover{
    margin-top:0px !important;
    background-color:#000;
}

.dark-popover .popover-body{
    color: #fff;
}

.dark-popover .popover-body p{
    margin: 0px;
}

.dark-popover ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.dark-popover ul li a:hover{
    text-decoration: underline;
}

.modal{
    z-index: 99999;
}

.no-text-transform{
    text-transform: none !important;
}

.hiw-pa-t0-r15{
    right: 15px !important;
    top: 0px !important;
}

.chat-message-item p{
    margin: 0px;
}

.no-border{
    border: 0px;
}

a{
    cursor: pointer !important;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    position: absolute;
    top: 4px;
    right: 3px;
    display: block;
    width: 12px;
    height: 12px;
    background: url('../images/chosen-sprite.png') -42px 1px no-repeat;
    font-size: 1px;
}

.chosen-container-multi .chosen-choices{
    border-bottom: 1px solid #e1e8ed !important;
    font-size: 14px;
    min-height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}

.chosen-container-multi .chosen-choices li.search-field input[type=text]{
    font-family: "Nunito Sans" !important;
    color: #2a2a2a !important;
}

.cm-upgrade-popup{
    padding: 30px !important;
}

.cm-upgrade-popup .close-icon{
    position: absolute;
    top:10px;
    right: 20px;
    cursor: pointer;
}

.no-margin{
    margin: 0px !important;
}

.chat-box .chat-list > div{
    transition: all 0.2s linear 0s;
}

.chat-box.cb-expanded{
    width: 450px;
}

.chat-box.cb-expanded .chat-list > div{
    height: 550px;
}

.ptfinder{
    margin-left: -25px;
    background: #F4F2F2;
    margin-right: -25px;
}

.ptfinder .ptfinder-cnt{
    padding: 20px;
}

.ptfinder .ptfinder-cnt .loader{
    text-align: center;
}

.ptfinder .ptfinder-cnt .loader img{
    width:30px;
}

.overview-section{

}

.overview-section .container-fluid{
    width: 90%;
}

.overview-section .tiles{
    background: #F8F9FD;
    padding: 20px 10px;
    border-radius: 2px;
}

.overview-section .tiles span{
    font-size: 17px;
    color: #000;
}

.overview-section .tiles h1{
    margin-top: 10px;
    color: #f05a24;
    font-size: 3rem;
    margin-bottom: 0;
}

.overview-section .overview-profile{
    display: flex;
    align-items: center;
    color: #000;
}

.overview-section .overview-profile img{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    margin-right: 10px;
}

.overview-section .overview-profile b{
    text-transform: capitalize;
}

.overview-headings{
    color: #000;
}

.overview-card{
    min-height: 450px;
    border-bottom-left-radius: 20px;
    border-bottom: 5px solid #f05a24;
    border-bottom-right-radius: 20px;
}

.overview-card .card-header{
    padding: 15px;
    background: #FFF;
    border-bottom: 1px solid #f4f2f2;
    margin: 0;
}

.overview-card .card-header h4{
    margin: 0;
    font-size: 20px;
}

.overview-card .card-body{

}

.overview-card .my-scions-list .lst{
    display: flex;
    margin-bottom: 20px;
}

.overview-card .my-scions-list .lst > div{
    display: block;
    margin-left: 10px;
}

.overview-card .card-body{
    line-height: normal !important;
}

.overview-card .my-scions-list .lst img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.overview-card .my-scions-list .lst span{
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    width: 100%;
    color: #000;
}

.overview-card .my-scions-list .lst p{
    margin: 0;
}

.overview-card .link{
    color: #f05a24;
}

.overview-card .my-idea-space-list .lst{
    display: flex;
    margin-bottom: 10px;
}

.overview-card .my-idea-space-list .lst > div{
    display: block;
    margin-left: 10px;
}

.overview-card .my-idea-space-list img{
    width: 80px;
    height: 80px;
    border-radius: 20%;
}

.overview-card .my-idea-space-list .lst span{
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    width: 100%;
    color: #000;
}

.overview-card .my-idea-space-list .lst p{
    margin: 0;
    line-height: 20px;
}

.img-circle-40{
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.img-rect-60{
    width: 60px;
    height: 60px;
    margin-right: 10px;
    object-fit: contain;
}

.img-rect-100{
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.overview-card .table thead th{
    border-bottom: 0;
    background: #E3E7F1;
    padding: 0.5rem;
    font-weight: 500;
    color: #727272;
}

.overview-card .table tbody tr{
    border-bottom: 1px solid #f4f2f2;
}

.overview-card .table tbody tr:last-child{
    border:0px;
}

.icon-mid{
    font-size: 20px;
}

.overview-section .tiles.tiles-box{
    margin: 10px 15px;
    text-align: center;
}

.overview-section .tiles.tiles-box p{
    margin: 0;
    line-height:24px;
}

.overview-section .tiles.tiles.tiles-box h1{
    margin: 0;
    font-size:2rem;
}

.flex-flow-wrap{
    flex-flow:wrap
}

.canvasjs-chart-credit{
    display: none;
}

.qcounter{
    display: flex;
    align-items: center;
}

.qcounter img{
    width: 36px;
    cursor: pointer;
}

.qcounter .qcounter-label{
    font-size: 26px;
    font-weight: 700;
    line-height: 30.47px;
    position: relative;
    top: 1px;
    margin: 0px 16px;
}

.qcounter.qcounter-disabled .qcounter-label{
    color: rgba(212, 212, 212, 1);
}

.qcounter.qcounter-disabled img{
    filter: invert(94%) sepia(9%) saturate(21%) hue-rotate(327deg) brightness(92%) contrast(85%);
}

.qcounter img.disabled{
    filter: invert(94%) sepia(9%) saturate(21%) hue-rotate(327deg) brightness(92%) contrast(85%);
}

.mt-m20{
    margin-top: -20px !important;
}

.ml-20{
    margin-left: 20px !important;
}

.mw-400{
    min-width: 300px;
}

.text-primary{
    color:#f05a24 !important
}

.frnds .nav-tabs .nav-item{
    margin-right: 15px;
}

.vm td{
    vertical-align: middle;
}

.line-clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2; /* Number of lines to show */
  }
  
  .line-clamp-1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1; /* Number of lines to show */
  }

  /* Fallback for older browsers */
  .fallback-line-clamp {
    max-height: 3.6em; /* Approximate two lines of text with 1.2em line height */
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-with-title{
    /* min-height: 600px; */
  }

  .card-with-title .title{
    background: #f0602C;
    color: #FFF;
    padding: 10px;
  }

  .card-with-title .title h5{
    margin: 0px;
    font-size: 16px;
    text-align: center;
  }

  .card-with-title p{
    margin: 0px;
  }

  .nav-tabs-heiglights{
    
  }

  .nav-tabs-heiglights .heiglighted{
  }

  .nav-tabs-heiglights .heiglighted a{
    color: #fff !important;
    background: #ed6031;
    padding: 10px 20px !important;
    display: block !important;
    font-weight: bold !important;
    font-size: 18px !important;
    border-radius: 5px 5px 0px 0px;
  }

  .nav-tabs-heiglights a{
    display: inline-block;
    padding: 10px 0px 10px 10px !important;
  }

  .mtr-btn.large{
    font-size: 20px;
    font-weight: bold;
    padding: 10px 40px;
  }

  .chart-heading{
    font-size: 17.5px;
    font-weight: 500;
    text-transform: capitalize;
    color: #424242;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .overview-card .tiles{
    display: flex;
    align-items: center;
    min-height:130px;
}

.overview-card .tiles.tiles-box{
    margin: 5px 15px !important;
    text-align: center !important;
}

.overview-card .tiles.tiles-box:first-child{
    margin-top: 15px !important;
}

.overview-card .tiles.tiles-box:last-child{
    margin-bottom: 15px !important;
}

.overview-card .tiles.tiles-box p{
    margin: 0 !important;
    line-height:24px !important;
}

.overview-card .tiles p{
    text-align: left;
}

.overview-card .tiles h1{
    min-width: 100px;
}

.custom-modal-content{
    max-height: 80vh;
    overflow: auto;
}

.custom-modal-content-org h5{
    color: #f05a24;
}

.custom-modal-content .pd-boxes-org{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.custom-modal-content .pd-boxes-org .pdb{
    background: #EBF8FE;
    border: 1px solid #c3d0d8;
    border-radius: 15px;
    min-width: 22%;
    max-width: 22%;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
    margin: 10px;
}

.custom-modal-content .pd-boxes-org .pdb div{
    display: block;
    width: 100%;
    color: #000;
}

.custom-modal-content .pd-boxes-org .pdb h4{
    margin: 0;
    color: #000;
}

.custom-modal-content .pd-boxes-org .pdb strong{
    margin-left: 10px;
    color: #000;
}

.logo-plan2{
    width:auto;
    margin: 0px 10px;
}

.angel-rank{
    background: #ed6637;
    display: inline-block;
    text-align: center;
    color: #FFF;
    border-radius: 5px;
    margin-top: 5px;
    padding: 2px 5px;
    font-size: 12px;
}

.form-group .mtrl-select::before{
    background: #ed6031 !important;
}